export default {
  methods: {
    confirm(mensagem) {
      return this.$swal.fire({
        title: "Atenção",
        text: mensagem,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
      });
    },

    showToast(val) {
      this.$swal.fire({
        position: "top-end",
        icon: val.type || "success",
        title: val.title,
        showConfirmButton: false,
        timer: 4000,
        toast: true,
      });
    },
  },
};
